import React from "react";
import SectionWrapper from "../../../UI/SectionWrapper";
import InfoCard from "../../../UI/ReasonCard";
import Title from "../../../UI/H4";
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from "react";
import { ReactComponent as TomatisIcon } from '../../../../assets/icon/reason-icon/tomatis-icon.svg';
import { ReactComponent as AquaIcon } from '../../../../assets/icon/reason-icon/aqua-icon.svg';
import { ReactComponent as SpeekIcon } from '../../../../assets/icon/reason-icon/speek-icon.svg';
import { ReactComponent as PsychologistIcon } from '../../../../assets/icon/reason-icon/psychologist-icon.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/icon/reason-icon/video-icon.svg';
import { ReactComponent as WeightlessnessTherapyIcon } from '../../../../assets/icon/reason-icon/weightlessness-therapy-icon.svg';
import { ReactComponent as AdosIcon } from '../../../../assets/icon/reason-icon/ados-2.svg';
import { ReactComponent as DoplerIcon } from '../../../../assets/icon/reason-icon/dopler-icon.svg';
import Slider from "../../../UI/Slider";
import { colors } from "../../../../theme/default";

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(23%, 1fr))',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const reasons = [
    {
        icon: AquaIcon,
        index: 1,
        title: "Акватерапія",
        // description: "Це напрям фізичної реабілітації, спрямований на відновлення організму в умовах водного середовища та його засобами з метою формування якісно нового рівня рухової активності.",
        link: "/services/aqua-therapy",
    },
    {
        icon: TomatisIcon,
        index: 2,
        title: "Курс сенсорно-звукової стимуляції “ТОМАТІС”",
        // description: "Це метод терапії, спрямований харчування, стимуляцію, розвиток нервової системи пацієнта. За допомогою \"Томатіс\" можна визначити ступінь відхилення від норми в психічному розвитку пацієнта.",
        link: "/services/tomatis-1-5-2",
    },
    {
        icon: SpeekIcon,
        index: 3,
        title: "Запуск мови",
        // description: "Наші фахівці в Space Clinic використовують різноманітні методики та підходи, щоб розмовляти будь-якого мовчуна. На заняттях із запуску мови діти навчаються: - правильно вимовляти звуки",
        link: "/services/start-language",
    },
    {
        icon: VideoIcon,
        index: 4,
        title: "Консультації провідних лікарів",
        // description: "need to add description",
        link: "/services",
    },
    {
        icon: PsychologistIcon,
        index: 5,
        title: "Психолог",
        // description: "need to add description",
        link: "/services/psychologist",
    },
    {
        icon: WeightlessnessTherapyIcon,
        index: 6,
        title: "Терапія невагомості",
        // description: "need to add description",
        link: "/services/zero-gravity-therapy",
    },
    {
        icon: AdosIcon,
        index: 7,
        title: "Діагностика аутизму ADOS - 2",
        // description: "need to add description",
        link: "/services/ados-2",
    },
    {
        icon: DoplerIcon,
        index: 8,
        title: "Функціональна діагностика",
        // description: "need to add description",
        link: "/services/dopler"
    },
];

function ReasonSection() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper paddingTop={50}>
            <Title>Чому ми</Title>
            <div className={classes.cardGrid}>
                {reasons.map((reason, index) => (
                    <InfoCard
                        key={index}
                        icon={reason.icon}
                        title={reason.title}
                        description={reason.description}
                        link={reason.link}
                    />
                ))}
            </div>
            <div>
                <Slider
                    projects={reasons}
                    lineColor={`${colors.tifanny}`}
                    slideComponent={({ project }) => (
                        <InfoCard
                            icon={project.icon}
                            title={project.title}
                            description={project.description}
                            link={project.link}
                        />
                    )}
                />
            </div>
        </SectionWrapper>
    );
}

export default ReasonSection;