import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Link} from '@material-ui/core';
import {colors} from '../../../theme/default';
import SectionWrapper from '../SectionWrapper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ContactForm from "../ContactForm";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    button: {
        textTransform: 'none',
        fontSize: '32px',
        fontWeight: '500',
        padding: '0',
        fontFamily: 'Inter-Regular',
        borderRadius: '0',
        textDecoration: 'none',
        display: 'flex',
        width: 'fit-content',
        justifyContent: 'flex-start',
        color: colors.outerSpace,
        '&:hover': {
            textDecoration: 'none',
            color: colors.tifanny,
        },
    },
    toggleButton: {
        textTransform: 'none',
        fontSize: '32px',
        fontWeight: '500',
        padding: '0',
        fontFamily: 'Inter-Regular',
        display: 'flex',
        alignItems: 'center',
        marginTop: '32px',
        justifyContent: 'space-between',
        color: colors.outerSpace,
        textAlign: 'left',
        '&:hover': {
            textDecoration: 'none',
            color: colors.tifanny,
        },
        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    navigationBlock: {
        listStyle: 'none',
        padding: 0,
        paddingLeft: '40px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginBottom: '32px',
        alignItems: 'flex-start',
    },
    navigationLink: {
        textTransform: 'none',
        textDecoration: 'none',
        opacity: '0.8',
        cursor: 'pointer',
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '400',
        marginBottom: '1px',
        textAlign: 'left',
        //paddingLeft: '40px',
        color: colors.outerSpace,
        '&:hover': {
            textDecoration: 'none',
            opacity: '1',
            color: colors.tifanny,
        },
    },
    subTitle: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '24px',
        textTransform: 'none',
        color: colors.outerSpace,
        textDecoration: 'none',
        marginBottom: '20px',
        paddingLeft: '20px',
        cursor: 'pointer',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        transition: 'color 0.3s ease',
        '&:hover': {
            textDecoration: 'none',
            color: colors.tifanny,
        },
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '32px',
    },
    li: {
        gridColumn: 'auto',
        gridRow: 'auto',
    },
    staticLink: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '32px',
        marginTop: '32px',
    },
    staticLinkList: {
        textTransform: 'none',
        textDecoration: 'none',
        textAlign: 'left',
        color: `${colors.outerSpace}`,
        '&:hover': {
            textDecoration: 'none',
            color: colors.tifanny,
        },
    },
    contactSocialWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '16px',
        marginTop: '40px',
    },
    footerTitle: {
        fontFamily: 'Inter-Regular',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '17px',
        color: `${colors.outerSpace}`,
        opacity: '0.6',
        marginBottom: '4px',
    },
    footerDescription: {
        fontFamily: 'Inter-Regular',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '17px',
        color: `${colors.outerSpace}`,
    },
    socialLinks: {
        display: "flex",
        flexDirection: "row",
        gap: '20px',
        "& a": {
            color: `${colors.outerSpace}`,
            fontFamily: 'Inter-Regular',
            fontSize: '20px',
            fontWeight: '400',
            textDecoration: "none",
            paddingBottom: '0',
            borderBottom: `1px solid ${colors.outerSpace}`,
            display: "block",
            '&:hover': {
                color: `${colors.tifanny}`,
                borderBottom: `1px solid ${colors.tifanny}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '17px',
            },
        },
    },
}));

function MobileToggleAccordion({tabsData, linkData}) {
    const classes = useStyles();
    const [openTabs, setOpenTabs] = useState(Array(tabsData.length).fill(false));
    const [openSubTitles, setOpenSubTitles] = useState(
        Array(tabsData.length).fill([]) // Initialize with empty arrays
    );

    const toggleTab = (index) => {
        const updatedOpenTabs = [...openTabs];
        updatedOpenTabs[index] = !updatedOpenTabs[index];
        setOpenTabs(updatedOpenTabs);
    };

    const toggleSubTitle = (tabIndex, subTitleIndex) => {
        const updatedOpenSubTitles = [...openSubTitles];
        updatedOpenSubTitles[tabIndex] = [...openSubTitles[tabIndex]];
        updatedOpenSubTitles[tabIndex][subTitleIndex] = !updatedOpenSubTitles[tabIndex][subTitleIndex];
        setOpenSubTitles(updatedOpenSubTitles);
    };

    return (
        <div className={classes.root}>
            <SectionWrapper paddingTop="0" paddingBottom="100px">
                <div className={classes.box}>
                    <Box>
                        {tabsData.map((tab, tabIndex) => (
                            <div key={tabIndex}>
                                <Button
                                    className={classes.toggleButton}
                                    onClick={() => toggleTab(tabIndex)}
                                >
                                    {tab.label}
                                    {openTabs[tabIndex] ? (
                                        <ExpandMoreIcon/>
                                    ) : (
                                        <ExpandMoreIcon style={{transform: 'rotate(90deg)'}}/>
                                    )}
                                </Button>
                                {openTabs[tabIndex] && (
                                    <Box style={{
                                        listStyle: 'none', paddingLeft: '10px', textDecoration: 'none',
                                    }}>
                                        {tab.items.map((item, subTitleIndex) => (
                                            <Box key={subTitleIndex}>
                                                <Button
                                                    className={classes.subTitle}
                                                    onClick={() => toggleSubTitle(tabIndex, subTitleIndex)}
                                                >
                                                    {item.subtitle}
                                                    {openSubTitles[tabIndex][subTitleIndex] ? (
                                                        <ExpandMoreIcon style={{width: '20px'}}/>
                                                    ) : (
                                                        <ExpandMoreIcon
                                                            style={{transform: 'rotate(90deg)', width: '20px'}}/>
                                                    )}
                                                </Button>
                                                {openSubTitles[tabIndex][subTitleIndex] && (
                                                    <Box className={classes.navigationBlock}>
                                                        {item.links.map((link, linkIndex) => (
                                                            <Button key={linkIndex}>
                                                                <Link href={link.url}
                                                                      className={classes.navigationLink}>
                                                                    {link.text}
                                                                </Link>
                                                            </Button>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </div>
                        ))}
                        <Box className={classes.staticLink}>
                            {linkData.map((link, index) => (
                                <Button className={classes.button}>
                                    <Link key={index} href={link.url} className={classes.staticLinkList}>
                                        {link.text}
                                    </Link>
                                </Button>

                            ))}
                        </Box>
                    </Box>
                </div>
                <Box className={classes.contactSocialWrapper}>
                    <Box className={classes.titleBlockWrapper}>
                        <Typography variant="h6" className={classes.footerTitle}>Адреса кліники</Typography>
                        <Typography className={classes.footerDescription}>
                            вулиця Шевченка, 133, Харків, Харківська область
                        </Typography>
                    </Box>
                    <Box className={classes.titleBlockWrapper}>
                        <Typography variant="h6" className={classes.footerTitle}>Ми в соціальних
                            мережах</Typography>
                        <div className={classes.socialLinks}>
                            <a href={"https://www.facebook.com/"}>Inst</a>
                            <a href={"https://www.instagram.com/"}>FB</a>
                            <a href={"https://www.twitter.com/"}>Telegram</a>
                        </div>
                    </Box>
                    <Box className={classes.titleBlockWrapper}>
                        <Typography variant="h6" className={classes.footerTitle}>Номер телефона:</Typography>
                        <Typography className={classes.footerDescription}>
                            +380 (50) 520 00 13
                        </Typography>
                    </Box>
                    <Box className={classes.contactFormWrapper}>
                        <ContactForm/>
                    </Box>
                </Box>
            </SectionWrapper>
        </div>
    );
}

export default MobileToggleAccordion;


