import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";
import {colors} from "../../../theme/default";
import {Link} from "react-router-dom";
import ContactModal from "../../pages/ModalWindow/AppointmentModal";


const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '52px 20px',
        borderRadius: '28px',
        border: '2px solid #1B282829',
        boxShadow: 'none',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            transition: 'all 0.3s ease-in-out',
            border: `2px solid ${colors.tifanny}`,
            boxShadow: '0px 0px 24px 0px #00244614',
            '& $name': {
                transition: 'all 0.3s ease-in-out',
                color: `${colors.tifanny}`,
            }
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '300px',
            padding: '20px',
            borderRadius: '16px',
        },
    },
    media: {
        width: 133,
        height: 133,
        marginRight: 20,
        objectFit: 'cover',
        aspectRatio: '1/1',
        objectPosition: 'center',
        borderRadius: '30px',
        [theme.breakpoints.down('sm')]: {
            width: 100,
            height: 100,
            borderRadius: '10px',
        },
    },
    content: {
        flex: '1',
        marginBottom: 32,
    },
    name: {
        fontWeight: 'bold',
        marginBottom: 10,
        fontFamily: 'Inter-Bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        transition: 'all 0.3s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    specialization: {
        fontFamily: 'Inter-Regular',
        fontSize: '20px',
        lineHeight: '24px',
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3, // Количество отображаемых строк текста
        whiteSpace: "break-spaces", // Поддержка переноса слов
        color: `${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    description: {
        fontFamily: 'Inter-Regular',
        fontSize: '16px',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        fontWeight: '500',
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4, // Количество отображаемых строк текста
        whiteSpace: "break-spaces", // Поддержка переноса слов
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            WebkitLineClamp: 3, // Количество отображаемых строк текста
            lineHeight: '20px',
        },
    },
    buttonWrapper: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

function EmployeeCard({ to, imageSrc, firstName, lastName, middleName, specialization, description, onClick }) {
    const classes = useStyles();

    return (
        <Box className={classes.card}>
            <Link to={to} style={{ textDecoration: 'none' }} onClick={onClick}>
                <Box style={{ display: 'flex', marginBottom: '32px' }}>
                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt={`${firstName} ${lastName}`}
                        image={imageSrc}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                        <Typography variant="h6" className={classes.name}>
                            {`${lastName} ${firstName} ${middleName}`}
                        </Typography>
                        <Typography variant="h6" className={classes.specialization}>
                            {specialization}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.content}>
                    <Typography variant="body2" className={classes.description}>
                        {description}
                    </Typography>
                </Box>
            </Link>
            <Box className={classes.buttonWrapper}>
                <ContactModal buttonText="Записатися на прийом" width="100%"/>
            </Box>
        </Box>
    );
}

export default EmployeeCard;
