import React from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {colors} from '../../../theme/default';
import {Box, Icon} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        height: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        textAlign: 'center',
        boxShadow: 'none',
        textDecoration: 'none',
        borderRadius: '20px',
        padding: '36px 20px 20px 20px',
        border: '2px solid rgba(27, 40, 40, 0.16)',
        transition: 'all 0.3s',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none',
            border: `2px solid ${colors.tifanny}`,
            boxShadow: '0px 0px 24px 0px #00244614',
            '& $path': {
                transition: 'all 0.3s',
                fill: `${colors.tifanny}`,
            },
            '& $title': {
                transition: 'all 0.3s',
                color: `${colors.tifanny}`,
            }
        },
        [theme.breakpoints.down('sm')]: {
            borderRadius: '18px',
            height: '250px',
        },
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // flex: '1',
    },
    title: {
        fontSize: '20px',
        fontWeight: '700',
        fontFamily: 'Inter-Bold',
        lineHeight: '24px',
        color: `${colors.outerSpace}`,
        margin: '38px 0 12px 0',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
            margin: '22px 0 10px 0',
        },
    },
    icon: {
        width: '143px',
        height: '143px',
        [theme.breakpoints.down('sm')]: {
            width: '83px',
            height: '83px',
        },
    },
    description: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Inter-Regular',
        lineHeight: '19px',
        textDecoration: 'none',
        color: `${colors.outerSpace}`,
        marginTop: 'auto',
        '&:hover': {
            textDecoration: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    navigationLink: {
        display: 'contents',
        textDecoration: 'none',
    }
}));

const InfoCard = ({icon, title, description, link}) => {
    const classes = useStyles();

    return (
        <Link className={classes.navigationLink} to={link}>
            <Box className={classes.card}>
                <Icon
                    component={icon}
                    className={classes.icon}
                    src={icon}
                />
                <Box className={classes.flexBox}>
                    <Typography
                        variant="h5"
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    {/*<Typography variant="body2" className={classes.description}>*/}
                    {/*    {description}*/}
                    {/*</Typography>*/}
                </Box>
            </Box>
        </Link>
    );
};

export default InfoCard;


