import React from "react";
import SectionWrapper from "../../../UI/SectionWrapper";
import Title from "../../../UI/H4";
import ImageOverlayCard from "../../../UI/ServiceCard";
import {Box} from "@material-ui/core";
import Tomatis from '../../../../assets/img/service/tomatis-preview.jpg';
import Soundsory from '../../../../assets/img/service/soundsory-preview.jpg';
import Akva from '../../../../assets/img/service/aqua-preview.jpg';
import StyledButton from "../../../UI/StyledButton";
import { useEffect } from "react";
import Slider from "../../../UI/Slider";
import {colors} from "../../../../theme/default";
import {makeStyles} from "@material-ui/core/styles";


const services = [
    {
        title: "Акватерапія",
        index: 1,
        description: "Акватерапія - це напрямок фізичної реабілітації, спрямований на відновлення організму.",
        imageSrc: Akva,
        link: '/services/aqua-therapy'
    },
    {
        title: "Комунікативна група",
        index: 2,
        description: "Комунікативна група - це групові заняття для дітей з порушеннями розвитку.",
        imageSrc: Tomatis,
        link: '/services/communicative-group'
    },
    {
        title: "Масаж ЛФК",
        index: 3,
        description: "Масаж ЛФК - це комплекс лікувальних процедур, які спрямовані на відновлення функцій організму.",
        imageSrc: Soundsory,
        link: '/services/lfk'
    },
];

const useStyles = makeStyles((theme) => ({
    imageOverlayWrapper: {
        display: 'flex',
        gap: '20px',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    buttonWrapper: {
            width: '25%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    sliderBox: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '32px',
        },
    },
}));

function ServicesSection() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper>
            <Title>Послуги</Title>
            <Box className={classes.imageOverlayWrapper}>
                {services.map((service, index) => (
                    <ImageOverlayCard
                        key={index}
                        title={service.title}
                        description={service.description}
                        imageSrc={service.imageSrc}
                        link={service.link}
                        boxShadow
                        bgColor
                    />
                ))}
            </Box>
            <div className={classes.sliderBox}>
                <Slider
                    projects={services}
                    lineColor={`${colors.tifanny}`}
                    slideComponent={({ project }) => (
                        <ImageOverlayCard
                            key={project.index}
                            title={project.title}
                            boxShadow
                            bgColor
                            description={project.description}
                            imageSrc={project.imageSrc}
                            link={project.link}
                        />
                    )}
                />
            </div>
            <Box className={classes.buttonWrapper}>
                <StyledButton text="Всі послуги та ціни" variant="outlined" width='100%' to="/services" />
            </Box>
        </SectionWrapper>
    );
}

export default ServicesSection;