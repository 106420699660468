import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ToggleMenu from '../ToggleMenu';
import { colors } from '../../../theme/default';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/icon/logo/Logo.svg';
import ContactModal from "../../pages/ModalWindow/AppointmentModal";
import FloatingButton from "../../pages/ModalWindow/FloatingModal";

const scrollToFooter = () => {
    const contacts = document.getElementById('contacts');
    if (contacts) {
        contacts.scrollIntoView({ behavior: 'smooth' });
    }
};

const navLinksData = [
    { url: '/services', text: 'Послуги та Ціни' },
    { url: '/stuff', text: 'Фахівці' },
    { url: '/about/feedbacks', text: 'Відгуки' },
    { url: '/about', text: 'Наша історія' },
    { url: '#contacts', text: 'Контакти', onClick: scrollToFooter },
];

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        transition: 'all 0.7s ease-out',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
    },
    scrolled: {
        backgroundColor: `${colors.white}`,
        borderBottom: '1px solid #444444',
    },
    toolbar: {
        border: '1px solid transparent',
        padding: '10px 40px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '20px 100px 20px 100px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '20px 150px 20px 150px',
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    navButton: {
        color: (props) => (props.scrolled ? `${colors.outerSpace}` : `${colors.white}`),
        fontSize: '20px',
        backgroundColor: 'transparent',
        fontFamily: 'Inter-Regular',
        textTransform: 'none',
        textDecoration: 'none',
        fontWeight: 400,
        lineHeight: '30px',
        padding: '0',
        '&:hover': {
            color: `${colors.tifanny}`,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '22px',
        },
    },
    logo: {
        width: '60px',
        height: '60px',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '34px',
    },
    buttonMobileView: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
            setScrolled(currentScrollPos > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, visible]);

    const classes = useStyles({ scrolled });

    return (
        <AppBar
            position="fixed"
            className={`${classes.appBar} ${scrolled ? classes.scrolled : ''} ${!visible ? classes.hidden : ''}`}
        >
            <Toolbar className={classes.toolbar}>
                <Box>
                    <a href="/">
                        <img src={Logo} alt="logo" className={classes.logo} />
                    </a>
                </Box>
                <Box className={classes.title}>
                    {navLinksData.map((link) => (
                        <Link
                            key={link.url}
                            to={link.url}
                            className={classes.navButton}
                            onClick={link.text === 'Контакти' ? scrollToFooter : null}
                        >
                            {link.text}
                        </Link>
                    ))}
                </Box>
                <Box className={classes.buttonWrapper}>
                    <FloatingButton />
                    <Box className={classes.buttonMobileView}>
                        <ContactModal buttonText="Консультація" titleText="Запис на консультацію" />
                    </Box>
                    <ToggleMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
