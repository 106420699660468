import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SectionWrapper from "../../../UI/SectionWrapper";
import { colors } from "../../../../theme/default";
import { useEffect } from "react";
import Counter from "../../../UI/Counter";

const useStyles = makeStyles((theme) => ({
    table: {
        display: 'flex',
        border: `1px solid ${colors.outerSpace}`,
        borderRadius: '20px',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            border: 'none',
        },
    },
    tableCell: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 16px',
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid ${colors.outerSpace}`,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    tableCellMiddle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 16px',
        borderRight: `1px solid ${colors.outerSpace}`,
        borderLeft: `1px solid ${colors.outerSpace}`,
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: `1px solid ${colors.outerSpace}`,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    description: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        color: `${colors.outerSpace}`,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            width: '50%',
            textAlign: 'left',
        },
    },
    tableCellUp: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 16px',
        [theme.breakpoints.down('sm')]: {
            borderTop: `1px solid ${colors.outerSpace}`,
            borderBottom: `1px solid ${colors.outerSpace}`,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
}));

function TableBlock() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper>
        <Paper className={classes.table}>
            <div className={classes.tableCellUp}>
                <div>
                    <Counter startValue={9999} endValue={10000} text="+" />
                </div>
                <Typography variant="body1" className={classes.description}>
                    Нових пацієнтів за цей рік
                </Typography>
            </div>
            <div className={classes.tableCellMiddle}>
                <div>
                    <Counter startValue={0} endValue={15} text="+" />
                </div>
                <Typography variant="body1" className={classes.description}>
                    Років досвіду у сфері реабілітації
                </Typography>
            </div>
            <div className={classes.tableCell}>
                <div>
                    <Counter startValue={0} endValue={64} text="+" />
                </div>
                <Typography variant="body1" className={classes.description}>
                    Послуги ми надаємо
                </Typography>
            </div>
        </Paper>
        </SectionWrapper>
    );
}

export default TableBlock;
